<template>
  <DocumentBase :config="documentConfig" />
</template>

<script>
import DocumentBase from '../document/components/DocumentBase.vue';

export default {
  name: 'RecordsManager',
  components: {
    DocumentBase
  },
  data() {
    return {
      documentConfig: {
        title: 'Actas',
        documentNameLabel: 'Nombre del Acta',
        documentNamePlaceholder: 'Ingrese el nombre del documento',
        documentType: 'gerentes',
        document: 'custom-seguridad',
        area: 'seguridad-informacion',
        redirectRoute: 'security',
        switches: [
          {
            model: 'is_multifirmas',
            label: 'Documento multifirmas'
          },
          {
            model: 'is_external',
            label: 'Documento externo'
          },
          {
            model: 'send_duva',
            label: 'Enviar copia por correo electrónico'
          },
          {
            model: 'coordinator_signature',
            label: 'Agregar firma de Lider de Administración de Usuarios',
            hidden: false
          }
        ]
      }
    };
  }
};
</script>
