<template>
  <div class="pl-8 pr-8">
    <v-row class="mt-11">
      <v-col class="text-left" cols="12">
        <h1 class="document-managmet___title">{{ config.title }}</h1>
      </v-col>
    </v-row>
    <v-row class="mt-10">
      <v-col cols="12">
        <div class="mb-xl-margin-file">
          <v-card elevation="0" class="px-15 py-15" style="border-radius: 20px">
            <!-- DOCUMENTO -->
            <template v-if="show == 1">
              <v-row>
                <v-col cols="12">
                  <p class="document-managmet___subtitle text-left">
                    {{ config.documentNameLabel || 'Nombre del documento' }} *
                  </p>
                  <v-form ref="formD">
                    <v-text-field
                      prepend-inner-icon="mdi-file-outline"
                      color="#466BE3"
                      v-model="formDocument.nombre"
                      :rules="[(v) => !!v || 'Este campo es requerido']"
                      :label="config.documentNamePlaceholder || 'Ingrese el nombre del documento'"
                    >
                    </v-text-field>
                  </v-form>
                </v-col>
                <v-col cols="12" class="pb-6">
                  <p class="document-managmet___subtitle text-left mb-0">Seleccione</p>
                  
                  <!-- Switches dinámicos basados en la configuración -->
                  <v-switch
                    v-for="(switch_config, index) in config.switches"
                    :key="index"
                    v-if="!switch_config.hidden"
                    class="pt-4"
                    v-model="formDocument[switch_config.model]"
                    :label="switch_config.label"
                    color="#466be3"
                    value="true"
                    hide-details
                    :disabled="switch_config.model === 'is_external' && formDocument.is_multifirmas !== 'true'"
                    @change="handleSwitchChange(switch_config.model)"
                  ></v-switch>

                  <!-- Selector de países -->
                  <v-select
                    v-if="formDocument.is_multifirmas === 'true'"
                    v-model="selectedCountry"
                    :items="countries"
                    item-text="pais"
                    item-value="pais"
                    label="País del documento *"
                    class="pt-8"
                    color="#466be3"
                    outlined
                    dense
                    :loading="loadingCountries"
                    @change="handleCountryChange"
                    :error-messages="countryError"
                    :rules="[v => !!v || 'El país es requerido']"
                    required
                  ></v-select>
                </v-col>

                <!-- Formulario de carga de archivo para documentos externos -->
                <v-col v-if="formDocument.is_external === 'true'" cols="12" class="pb-6">
                  <p class="document-managmet___subtitle text-left mb-4">
                    Cargar documento PDF
                  </p>
                  
                  <!-- Sección de información -->
                  <v-card outlined class="mb-6" style="background-color: #466BE3;">
                    <v-card-text>
                      <div class="d-flex align-center mb-4">
                        <v-icon color="white" class="mr-2">mdi-information</v-icon>
                        <span class="text-subtitle-1 font-weight-bold" style="color: white;">Instrucciones para cargar el documento</span>
                      </div>
                      <ul class="pl-4" style="color: white; list-style: none;">
                        <li class="mb-2"><b>El archivo debe estar en formato PDF</b></li>
                        <li class="mb-2"><b>El tamaño máximo del archivo es de 3MB</b></li>
                        <li class="mb-2"><b>El documento debe contar con el espacio para firmar</b></li>
                      </ul>
                    </v-card-text>
                  </v-card>

                  <v-file-input
                    v-model="pdfFile"
                    accept="application/pdf"
                    label="Seleccionar archivo PDF"
                    prepend-icon="mdi-file-pdf-box"
                    :rules="[
                      v => !!v || 'El archivo es requerido',
                      v => !v || v.size <= 3 * 1024 * 1024 || 'El archivo no debe superar los 3MB'
                    ]"
                    @change="handleFileUpload"
                    :error-messages="fileError"
                  ></v-file-input>
                </v-col>

                <!-- Variables y plantillas solo si no es documento externo -->
                <template v-if="formDocument.is_external !== 'true'">
                  <v-col cols="12">
                    <v-row no-gutters>
                      <v-col cols="3">
                        <p class="document-managmet___subtitle pb-4 pt-5">
                          {{ config.variablesLabel || 'Diligencie el documento con las variables requeridas' }}
                        </p>
                      </v-col>

                      <v-col cols="9">
                        <v-slide-group
                          show-arrows
                          class="variable-carousel"
                        >
                          <v-slide-item
                            v-for="n in listField"
                            :key="n"
                            v-slot="{ active, toggle }"
                          >
                            <v-chip
                              class="ma-2"
                              color="#4CAF50"
                              dark
                              :id="'field-' + n"
                              @click="copy(n)"
                              pill
                            >
                              {{ n }}
                            </v-chip>
                          </v-slide-item>
                        </v-slide-group>
                      </v-col>
                    </v-row>
                  </v-col>

                  <!-- Template section -->
                  <v-col cols="12" class="pb-6">
                    <p class="document-managmet___subtitle pb-4">
                      Seleccione una plantilla
                    </p>
                    <v-slide-group
                      multiple
                      show-arrows
                      class="my-4"
                      prev-icon="mdi-chevron-left-circle-outline"
                      next-icon="mdi-chevron-right-circle-outline"
                    >
                      <v-slide-item
                        class="my-0 py-2"
                        v-for="data in listTemplate"
                        :key="data.id"
                        v-slot="{ active, toggle }"
                      >
                        <v-card
                          :input-value="active"
                          class="pt-2 pb-1 mx-3 card-template-d text-center"
                          @click="updateTemplate(data), toggle"
                          style="border-radius: 15px"
                          width="200"
                          outlined
                          elevation="0"
                        >
                          <v-card-title class="py-0 my-0">
                            <v-btn
                              @click="deleteTemplate(data)"
                              color="red"
                              icon
                              small
                              style="display: flex; left: 150px; bottom: 0px"
                            >
                              <v-icon> mdi-delete </v-icon>
                            </v-btn>
                          </v-card-title>
                          <v-tooltip max-width="300" color="#000" bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-card-text
                                v-bind="attrs"
                                v-on="on"
                                class="pt-0 pb-1"
                              >
                                <img
                                  class="text-center"
                                  :src="origin + '/icon/service-2.svg'"
                                  width="60"
                                />
                                <v-layout justify-center>
                                  <p class="document-managmet___subtitle mt-3">
                                    {{ data.name }}
                                  </p>
                                </v-layout>
                              </v-card-text>
                            </template>
                            <span> {{ data.description }}</span>
                          </v-tooltip>
                        </v-card>
                      </v-slide-item>
                    </v-slide-group>
                    <v-alert type="info" text v-if="listTemplate.length == 0">
                      No se encontraron plantillas
                      <v-btn
                        @click="openTemplate = true"
                        color="info"
                        dark
                        rounded
                        small
                        class="ml-4"
                        ><strong> Agregar nueva plantilla </strong></v-btn
                      >
                    </v-alert>
                    <v-layout justify-end>
                      <v-btn
                        v-if="formDocument.html != ''"
                        @click="openTemplate = true"
                        class="mb-1 mt-4 mr-4"
                        color="#466BE3"
                        rounded
                        dark
                        width="260"
                      >
                        <strong> Guardar nueva plantilla </strong>
                      </v-btn>
                    </v-layout>
                  </v-col>

                  <v-col cols="12">
                    <vue-editor
                      ref="editor"
                      v-model="formDocument.html"
                      :editor-toolbar="customToolbar"
                    ></vue-editor>
                  </v-col>
                </template>
              </v-row>
              <v-row justify="center">
                <v-col cols="6">
                  <v-btn
                    @click="previewFile()"
                    style="background-color: #fff"
                    block
                    rounded
                    outlined
                    color="#466BE3"
                  >
                    anterior
                  </v-btn>
                </v-col>

                <v-col cols="6">
                  <v-btn
                    @click="nextForm()"
                    block
                    rounded
                    color="#466be3"
                    dark
                    class="document-manager__button"
                  >
                    Siguiente
                  </v-btn>
                </v-col>
              </v-row>
            </template>

            <!-- SELECCIÓN DE USUARIOS -->
            <template v-if="show == 2">
              <v-row>
                <v-col cols="12">
                  <p class="document-managmet___subtitle text-left">
                    Seleccione las cédulas a las cuales va a asignar el documento
                    <template v-if="formDocument.is_multifirmas !== 'true'">
                      con su respectivo monto &nbsp;&nbsp;
                      <v-btn
                        rounded
                        dark
                        color="#466be3"
                        @click="openImport = true"
                      >
                        IMPORTACIÓN MASIVA
                      </v-btn>
                    </template>
                  </p>
                  
                  <!-- Autocomplete para buscar usuarios -->
                  <v-autocomplete
                    v-model="selectedUsers"
                    :items="listUsers"
                    :loading="loadingUsers"
                    :search-input.sync="search"
                    item-text="full_name"
                    item-value="id"
                    label="Buscar por nombre o documento"
                    placeholder="Escriba para buscar..."
                    prepend-icon="mdi-account-search"
                    return-object
                    multiple
                    chips
                    small-chips
                    deletable-chips
                    clearable
                    @change="handleMultipleUserSelection"
                  >
                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title class="capitalize-text">
                          {{ item.full_name }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          Doc: {{ item.document_number }} | {{ item.profile && item.profile.name || 'Sin cargo' }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-col>

                <!-- Tabla de usuarios seleccionados -->
                <v-col cols="12" class="mt-4">
                  <v-data-table
                    :headers="[
                      { text: 'Foto', value: 'photo', sortable: false, align: 'center' },
                      { text: 'Nombre', value: 'full_name' },
                      { text: 'Documento', value: 'document_number' },
                      { 
                        text: 'Cargo', 
                        value: 'profile.name', 
                        format: value => {
                          if (!value) return 'Sin cargo';
                          return value.split('|')[0].trim();
                        }
                      },
                      { text: 'Acciones', value: 'actions', sortable: false }
                    ]"
                    :items="selectedUsers"
                    :loading="loadingTable"
                    class="elevation-1"
                  >
                    <template v-slot:item.photo="{ item }">
                      <v-avatar size="40">
                        <v-img
                          :src="item.photo || 'https://cdn.vuetifyjs.com/images/john.jpg'"
                          :alt="item.full_name"
                        ></v-img>
                      </v-avatar>
                    </template>
                    <template v-slot:item.actions="{ item }">
                      <v-icon
                        small
                        color="red"
                        @click="removeUser(item)"
                      >
                        mdi-delete
                      </v-icon>
                    </template>
                    <template v-slot:item.full_name="{ item }">
                      <span class="capitalize-text">{{ item.full_name }}</span>
                    </template>
                    <template v-slot:item.profile.name="{ item }">
                      <template v-if="formDocument.is_multifirmas === 'true' && formDocument.is_external !== 'true'">
                        <v-edit-dialog
                          :return-value.sync="item.profile.name"
                          @save="updateUserCargo(item)"
                          @keydown.enter="updateUserCargo(item)"
                        >
                          <template v-slot:default>
                            <div>{{ item.profile.name }}</div>
                          </template>
                          <template v-slot:input>
                            <v-text-field
                              v-model="item.profile.name"
                              label="Editar"
                              single-line
                              autofocus
                              @keydown.enter="updateUserCargo(item)"
                            ></v-text-field>
                          </template>
                        </v-edit-dialog>
                      </template>
                      <template v-else>
                        <div>{{ item.profile.name }}</div>
                      </template>
                    </template>
                  </v-data-table>
                </v-col>

                <!-- Botones de navegación -->
                <v-col cols="12" class="mt-4">
                  <v-row justify="space-between">
                    <v-col cols="auto">
                      <v-btn
                        color="primary"
                        outlined
                        @click="show = 1"
                      >
                        Anterior
                      </v-btn>
                    </v-col>
                    <v-col cols="auto">
                      <v-btn
                        color="primary"
                        :disabled="!selectedUsers.length"
                        @click="assignDocument"
                      >
                        Asignar documento
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </template>

            <!-- Diálogo de importación masiva -->
            <v-dialog v-model="openImport" width="600" persistent>
              <v-card style="border-radius: 15px">
                <v-card-text class="py-10">
                  <p class="document-managmet___txt-dialog-title">
                    Adjuntar archivo de importación
                  </p>
                  <p class="pb-0 mb-2 text-left">
                    El archivo debe contar con lo siguiente:
                  </p>
                  <p class="pb-0 mb-2 text-left">
                    1. una columna para la cédula, esta puede tener como título las
                    siguientes variables: <strong> cc, cedula, documento </strong>.
                  </p>
                  <p class="pb-0 mb-2 text-left">
                    2. una columna para el valor a pagar, esta puede tener como título
                    las siguientes variables:
                    <strong>valor, monto, total, pago</strong>.
                  </p>
                  <p class="pb-0 mb-2 text-left">
                    3. Recuerde que en los dos puntos anteriores las variables no deben
                    tener tildes, ni caracteres especiales.
                  </p>
                  <p class="pb-0 mb-2 text-left">
                    4. Debe ser un archivo tipo excel (xlsx) que no supere 1MB de peso.
                  </p>

                  <v-file-input
                    class="mt-8"
                    v-model="file"
                    color="#466BE3"
                    counter
                    label="Adjuntar archivo"
                    prepend-icon="mdi-paperclip"
                    outlined
                    :show-size="1000"
                  >
                    <template v-slot:selection="{ text }">
                      <v-chip color="#466BE3" dark label small>
                        {{ text }}
                      </v-chip>
                    </template>
                  </v-file-input>
                </v-card-text>
                <v-card-actions class="pb-8">
                  <v-row>
                    <v-col cols="6">
                      <v-btn
                        block
                        color="#466BE3"
                        dark
                        rounded
                        @click="openImport = false"
                        outlined
                      >
                        CERRAR
                      </v-btn>
                    </v-col>
                    <v-col cols="6">
                      <v-btn
                        block
                        color="#466BE3"
                        dark
                        rounded
                        :loading="loadingBtn"
                        @click="importData"
                      >
                        ADJUNTAR
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <!-- Diálogo para guardar plantilla -->
            <v-dialog v-model="openTemplate" width="500" persistent>
              <v-card style="border-radius: 15px">
                <v-card-title class="pt-6">
                  <v-layout justify-center>
                    <h3 class="document-managmet___txt-dialog-title">Guardar Plantilla</h3>
                  </v-layout>
                  <v-btn
                    @click="openTemplate = false"
                    color="#466BE3"
                    icon
                    x-small
                    style="display: flex; left: 18px; bottom: 45px"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-card-title>
                <v-card-text>
                  <v-form ref="formTemplate" v-model="validTemplate">
                    <v-text-field
                      v-model="formTemplate.name"
                      label="Nombre de la plantilla"
                      :rules="[v => !!v || 'El nombre es requerido']"
                      required
                    ></v-text-field>
                    <v-textarea
                      v-model="formTemplate.description"
                      label="Descripción"
                      :rules="[v => !!v || 'La descripción es requerida']"
                      required
                    ></v-textarea>
                  </v-form>
                </v-card-text>
                <v-card-actions class="pb-8">
                  <v-row>
                    <v-col cols="6">
                      <v-btn
                        block
                        color="#466BE3"
                        dark
                        rounded
                        @click="openTemplate = false"
                        outlined
                      >
                        CANCELAR
                      </v-btn>
                    </v-col>
                    <v-col cols="6">
                      <v-btn
                        block
                        color="#466BE3"
                        dark
                        rounded
                        :loading="loadingBtn"
                        :disabled="!validTemplate"
                        @click="saveTemplate"
                      >
                        GUARDAR
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <!-- Diálogo de confirmación para eliminar plantilla -->
            <v-dialog v-model="openConfirmDelete" max-width="400">
              <v-card style="border-radius: 15px">
                <v-card-title class="pt-6">
                  <v-layout justify-center>
                    <h3 class="document-managmet___txt-dialog-title">Confirmar Eliminación</h3>
                  </v-layout>
                  <v-btn
                    @click="openConfirmDelete = false"
                    color="#466BE3"
                    icon
                    x-small
                    style="display: flex; left: 18px; bottom: 45px"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-card-title>
                <v-card-text class="pt-4">
                  <p class="text-center">¿Está seguro que desea eliminar esta plantilla?</p>
                  <p class="text-center text-subtitle-2 grey--text">{{ templateToDelete ? templateToDelete.name : '' }}</p>
                </v-card-text>
                <v-card-actions class="pb-8">
                  <v-row>
                    <v-col cols="6">
                      <v-btn
                        block
                        color="#466BE3"
                        dark
                        rounded
                        @click="openConfirmDelete = false"
                        outlined
                      >
                        CANCELAR
                      </v-btn>
                    </v-col>
                    <v-col cols="6">
                      <v-btn
                        block
                        color="error"
                        dark
                        rounded
                        :loading="loadingBtn"
                        @click="confirmDeleteTemplate"
                      >
                        ELIMINAR
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card>
        </div>
      </v-col>
    </v-row>
    <Alert
      v-model="alert.show"
      :type="alert.type"
      :text="alert.text"
    />
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
import Api from "@/utils/api";
import axios from "axios";
import Vue from 'vue';
import Alert from "@/components/Alert.vue";

export default {
  name: 'DocumentBase',
  components: {
    VueEditor,
    Alert
  },
  props: {
    config: {
      type: Object,
      required: true,
      default: () => ({
        title: 'Documento',
        documentNameLabel: 'Nombre del documento',
        documentNamePlaceholder: 'Ingrese el nombre del documento',
        variablesLabel: 'Diligencie el documento con las variables requeridas',
        documentType: 'gerentes',
        document: 'custom',
        area: 'relaciones-laborales',
        redirectRoute: 'home',
        switches: [
          {
            model: 'send_pbot',
            label: 'Enviar documento PBOT'
          },
          {
            model: 'send_duva',
            label: 'Enviar copia por correo electrónico'
          },
          {
            model: 'coordinator_signature',
            label: 'Agregar firma de Gerente de Relaciones Laborales'
          }
        ]
      })
    }
  },
  data() {
    return {
      origin: window.location.origin,
      show: 1,
      loadingAll: false,
      loadingBtn: false,
      openConfirm: false,
      openImport: false,
      file: null,
      countryError: '',
      alert: {
        show: false,
        text: '',
        type: 'error'
      },
      formDocument: {
        document_id: null,
        nombre: "",
        document_type: this.config.documentType || '',
        document: this.config.document || '',
        coordinator_signature: null,
        send_duva: null,
        send_pbot: null,
        html: "",
        is_multifirmas: false,
        is_external: false
      },
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline", "strike"],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ color: [] }, { background: [] }],
      ],
      listUsers: [],
      listSelectedUsers: [],
      listField: [],
      documentId: null,
      token: "",
      name: "",
      infoToken: {
        document: "",
      },
      currentInfoDoc: null,
      htmlInit: "",
      listTemplate: [],
      formTemplate: {
        html: "",
        type: this.config.documentType || '',
        name: "",
        description: "",
      },
      openTemplate: false,
      isTemplate: false,
      newHtml: "",
      documentName: '',
      htmlContent: '',
      pdfFile: null,
      isExternal: false,
      switches: {
        coordinator_signature: false,
        send_duva: false,
        send_pbot: false,
        check_dotacion: false
      },
      loading: false,
      error: null,
      countries: [],
      selectedCountry: null,
      loadingCountries: false,
      selectedUsers: [],
      loadingUsers: false,
      search: '',
      loadingTable: false,
      fileError: '',
      validTemplate: false,
      openConfirmDelete: false,
      templateToDelete: null,
    };
  },
  methods: {
    async getToken() {
      try {
        const tokenB64 = localStorage.getItem("tokenB64");
        if (!tokenB64) {
          return;
        }

        const token = localStorage.getItem("token");
        if (!token) {
          return;
        }

        try {
          this.token = tokenB64;
          const parts = token.split('.');
          if (parts.length >= 2) {
            this.infoToken = JSON.parse(atob(parts[1]));
          }
          
          if (!this.token) {
            return;
          }
          
          await this.initializeData();
        } catch (e) {
          console.error('Error al procesar el token:', e);
        }
      } catch (error) {
        console.error('Error al obtener el token:', error);
      }
    },
    async initializeData() {
      try {
        // Primero obtenemos las plantillas, campos y países ya que son esenciales
        await Promise.all([
          this.getFieldsCurrent(),
          this.getTemplate(),
          this.getCountries()
        ]);

        // Intentamos obtener los usuarios pero no bloqueamos si falla
        try {
          await this.getUsers();
        } catch (error) {
          console.log('No se pudieron cargar los usuarios, continuando con la funcionalidad básica');
        }
      } catch (error) {
        console.error("Error initializing data:", error);
      }
    },
    async getUsers() {
      try {
        const response = await Api.Auth().getUsers();
        
        if (response && response.data && response.data.data) {
          this.listUsers = response.data.data;
        } else {
          this.listUsers = [];
        }
      } catch (error) {
        console.error("Error en getUsers:", error);
        this.listUsers = [];
        throw error;
      }
    },
    async getFieldsCurrent() {
      this.loadingAll = true;
      try {
        const response = await Api.Auth().currentUser(this.token);
        if (response.data.cod == 0) {
          var lField = Object.keys(response.data.data);
          this.listField = lField.filter(
            (val) => val != "id" && val != "isAdmin"
          );
          for (let i = 0; i < this.listField.length; i++) {
            this.listField[i] = "{{" + this.listField[i] + "}}";
          }
          
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingAll = false;
      }
    },
    async getTemplate() {
      if (!this.token || !this.config.documentType || !this.config.area) {
        return;
      }

      try {
        const tokenToUse = `Bearer ${this.token}`;
        const response = await Api.DocumentTemplate().getDocumentTemplate(
          tokenToUse,
          this.config.documentType,
          this.config.area
        );

        if (response && response.data && response.data.data) {
          this.listTemplate = response.data.data;
        } else {
          this.listTemplate = [];
        }
      } catch (error) {
        console.error("[getTemplate] Error obteniendo plantillas:", error);
        if (error.response) {
          console.error('[getTemplate] Detalles del error:', {
            status: error.response.status,
            data: error.response.data,
            url: error.response.config?.url
          });
        }
        this.listTemplate = [];
      }
    },
    updateTemplate(template) {
      this.formDocument.html = template.html;
    },
    async deleteTemplate(template) {
      // Evitar que el evento se propague al click de la tarjeta
      event.stopPropagation();
      this.templateToDelete = template;
      this.openConfirmDelete = true;
    },
    async confirmDeleteTemplate() {
      try {
        this.loadingBtn = true;
        const response = await Api.DocumentTemplate().deleteTemplate(this.token, {
          id: this.templateToDelete.id,
        });

        if (response && response.data) {
          this.alert = {
            show: true,
            text: 'Plantilla eliminada correctamente',
            type: 'success'
          };
          await this.getTemplate();
        }
      } catch (error) {
        console.error("Error deleting template:", error);
        this.alert = {
          show: true,
          text: error.response?.data?.message || 'Error al eliminar la plantilla',
          type: 'error'
        };
      } finally {
        this.loadingBtn = false;
        this.openConfirmDelete = false;
        this.templateToDelete = null;
      }
    },
    copy(field) {
      // Obtener referencia al editor
      const editor = this.$refs.editor;
      if (editor) {
        // Enfocar el editor
        editor.quill.focus();
        
        // Obtener la posición actual del cursor
        const range = editor.quill.getSelection();
        if (range) {
          // Insertar el texto en la posición del cursor
          editor.quill.insertText(range.index, field);
          
          // Mostrar mensaje de éxito
          this.alert = {
            show: true,
            text: 'Variable agregada al documento',
            type: 'success'
          };
        } else {
          // Si no hay cursor, insertar al final
          editor.quill.insertText(editor.quill.getLength(), field);
          
          // Mostrar mensaje de éxito
          this.alert = {
            show: true,
            text: 'Variable agregada al final del documento',
            type: 'success'
          };
        }
      }
    },
    previewFile() {
      // Implementar vista previa si es necesario
    },
    async handleNextStep() {
      if (!this.validateForm()) return;

      try {
        this.loadingAll = true;
        
        // Si es multifirmas, usamos el endpoint crearDocumento
        if (this.formDocument.is_multifirmas === 'true') {
          const formData = new FormData();
          
          // Campos básicos
          formData.append('nombre', this.formDocument.nombre);
          formData.append('document_type', this.config.documentType);
          formData.append('document', 'multiples_firmas');
          formData.append('area', this.config.area);
          formData.append('pais', this.selectedCountry);
          
          // Campos booleanos
          formData.append('coordinator_signature', this.formDocument.coordinator_signature === 'true');
          formData.append('send_duva', this.formDocument.send_duva === 'true');
          formData.append('send_pbot', this.formDocument.send_pbot === 'true');
          formData.append('check_dotacion', false);
          formData.append('documento_externo', this.formDocument.is_external === 'true');
          
          // Manejo del documento (PDF o HTML)
          if (this.formDocument.is_external === 'true') {
            if (this.pdfFile) {
              formData.append('pdf', this.pdfFile);
            }
          } else {
            formData.append('html', this.formDocument.html);
          }

          console.log('Enviando FormData:', Object.fromEntries(formData.entries()));
          const response = await Api.documentosMultiFirmas().crearDocumento(this.token, formData);
          
          if (response && response.data) {
            this.documentId = response.data;
            this.show = 2;
          } else {
            throw new Error('Error al crear el documento: Respuesta inválida del servidor');
          }
        } 
        // Si no es multifirmas, usamos createDocumentToSign con el document original de la config
        else {
          const data = {
            ...this.formDocument,
            document_type: this.config.documentType,
            document: this.config.document,
            area: this.config.area,
            check_value: this.formDocument.check_value === 'true',
            send_duva: this.formDocument.send_duva === 'true',
            coordinator_signature: this.formDocument.coordinator_signature === 'true',
            send_pbot: this.formDocument.send_pbot === 'true'
          };

          const response = await Api.Auth().createDocumentToSign(this.token, data);
          
          if (response.data.cod === 0) {
            this.documentId = response.data.data.id;
            this.formDocument = {
              ...this.formDocument,
              ...response.data.data,
              document_id: response.data.data.id,
              document_type: this.config.documentType
            };
            this.show = 2;
          }
        }
      } catch (error) {
        console.error('Error al crear documento:', error);
        if (error.response && error.response.data) {
          this.alert = {
            show: true,
            text: error.response.data.error || 'Error al crear el documento',
            type: 'error'
          };
        }
      } finally {
        this.loadingAll = false;
      }
    },
    validateForm() {
      // Primero validamos el nombre del documento
      if (!this.formDocument.nombre) {
        this.alert = {
          show: true,
          text: 'El nombre del documento es requerido',
          type: 'error'
        };
        return false;
      }

      // Validamos el país solo si es multifirmas
      if (this.formDocument.is_multifirmas === 'true' && !this.selectedCountry) {
        this.countryError = 'El país es requerido';
        this.alert = {
          show: true,
          text: 'Debe seleccionar un país',
          type: 'error'
        };
        return false;
      }
      this.countryError = '';

      // Validamos el archivo PDF si es documento externo
      if (this.formDocument.is_external === 'true') {
        if (!this.pdfFile) {
          this.alert = {
            show: true,
            text: 'Debe seleccionar un archivo PDF',
            type: 'error'
          };
          return false;
        }
        if (this.pdfFile.size > 3 * 1024 * 1024) {
          this.alert = {
            show: true,
            text: 'El archivo no debe superar los 3MB',
            type: 'error'
          };
          return false;
        }
        if (this.pdfFile.type !== 'application/pdf') {
          this.alert = {
            show: true,
            text: 'El archivo debe ser PDF',
            type: 'error'
          };
          return false;
        }
      } else {
        // Si no es documento externo, validamos el HTML
        if (!this.formDocument.html || this.formDocument.html.trim() === '') {
          this.alert = {
            show: true,
            text: 'El contenido del documento es requerido',
            type: 'error'
          };
          return false;
        }
      }
      
      return true;
    },
    nextForm() {
      if (this.isTemplate && this.formDocument.html !== this.newHtml) {
        this.openTemplate = true;
      } else {
        this.handleNextStep();
      }
    },
    async assignDocument() {
      try {
        console.log('[DocumentBase] Iniciando asignación de documento');
        this.loadingAll = true;
        
        if (!this.selectedUsers.length) {
          console.warn('[DocumentBase] No hay usuarios seleccionados para asignar');
          this.alert = {
            show: true,
            text: 'Debe seleccionar al menos un usuario',
            type: 'error'
          };
          return;
        }

        const tokenB64 = localStorage.getItem("tokenB64");
        if (!tokenB64) {
          console.error('[DocumentBase] No se encontró el token de autenticación');
          this.alert = {
            show: true,
            text: 'Error de autenticación. Por favor, inicie sesión nuevamente.',
            type: 'error'
          };
          return;
        }

        console.log('[DocumentBase] Tipo de documento:', this.formDocument.is_multifirmas === 'true' ? 'multifirmas' : 'normal');
        let response;
        
        try {
          if (this.formDocument.is_multifirmas === 'true') {
            const data = {
              doc_id: parseInt(this.documentId),
              users: this.selectedUsers.map(user => ({
                dsu_user_id: parseInt(user.id),
                nombre: user.full_name,
                cargo: user.profile?.name || 'Sin cargo'
              }))
            };
            
            console.log('[DocumentBase] Enviando solicitud para documento multifirmas:', data);
            response = await Api.documentosMultiFirmas().asignarDocumento(tokenB64, data);
          } else {
            const data = {
              documentSignId: parseInt(this.documentId),
              people: this.selectedUsers.map(user => ({
                documentNumber: user.document_number
              }))
            };

            console.log('[DocumentBase] Enviando solicitud para documento normal:', data);
            response = await Api.Auth().createUsersDocumentsToSign(tokenB64, data);
          }

          console.log('[DocumentBase] Respuesta de asignación:', response);

          if (response && (response.message || (response.data && response.data.cod === 0))) {
            console.log('[DocumentBase] Asignación exitosa, actualizando lista de documentos');
            
            try {
              // Esperamos un poco antes de actualizar la lista para dar tiempo a que el servidor procese
              await new Promise(resolve => setTimeout(resolve, 1000));
              
              // Intentamos obtener los documentos directamente
              const config = {
                headers: {
                  Authorization: "Bearer " + tokenB64
                }
              };
              
              // Usamos Auth() en lugar de noAuth()
              const documentsResponse = await Api.Auth().signCompleted(config);
              
              if (documentsResponse && documentsResponse.data) {
                console.log('[DocumentBase] Documentos obtenidos directamente:', documentsResponse.data);
                await this.$store.dispatch("updateDocuments", documentsResponse.data);
              }
            } catch (updateError) {
              console.error('[DocumentBase] Error al actualizar lista de documentos:', updateError);
              // No mostramos el error al usuario ya que el documento se asignó correctamente
            }
            
            // Redirigimos independientemente de si la actualización fue exitosa
            console.log('[DocumentBase] Redirigiendo a:', this.config.redirectRoute);
            this.$router.push({ name: this.config.redirectRoute });
          } else {
            throw new Error('La respuesta del servidor no tiene el formato esperado');
          }
        } catch (error) {
          console.error('[DocumentBase] Error en la operación:', error);
          throw new Error(
            error.response?.data?.message || 
            error.message || 
            'Ocurrió un error al procesar la solicitud'
          );
        }
      } catch (error) {
        console.error('[DocumentBase] Error al asignar documento:', error);
        this.alert = {
          show: true,
          text: error.message || 'Error al asignar el documento',
          type: 'error'
        };
      } finally {
        this.loadingAll = false;
      }
    },
    async previewDocument(user) {
      try {
        this.loadingAll = true;
        const response = await Api.documentosMultiFirmas().previewDocumento({
          document_id: this.documentId,
          user_id: user.id
        });
        
        // Crear un blob con la respuesta y abrir en nueva ventana
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        window.open(url, '_blank');
      } catch (error) {
        console.error('Error al previsualizar documento:', error);
      } finally {
        this.loadingAll = false;
      }
    },
    handleFileUpload(file) {
      if (!file) {
        this.fileError = '';
        return;
      }

      if (file.type !== 'application/pdf') {
        this.fileError = 'El archivo debe ser PDF';
        this.pdfFile = null;
        return;
      }

      if (file.size > 3 * 1024 * 1024) {
        this.fileError = 'El archivo no debe superar los 3MB';
        this.pdfFile = null;
        return;
      }

      this.fileError = '';
    },
    validateDocument() {
      if (!this.documentName) {
        return false;
      }

      if (!this.isExternal && !this.htmlContent) {
        return false;
      }

      if (this.isExternal && !this.pdfFile) {
        return false;
      }

      if (!this.config.documentType || !this.config.document || !this.config.area) {
        return false;
      }

      return true;
    },
    async getCountries() {
      try {
        this.loadingCountries = true;
        const response = await Api.ubicacion().getPaises();
        
        if (response && response.data && response.data.data) {
          this.countries = response.data.data;
        } else {
          this.countries = [];
        }
      } catch (error) {
        console.error('Error al cargar países:', error);
        this.countries = [];
      } finally {
        this.loadingCountries = false;
      }
    },
    handleCountryChange(value) {
      console.log('País seleccionado:', value);
      // Aquí puedes agregar la lógica adicional que necesites cuando se seleccione un país
    },
    handleMultipleUserSelection(users) {
      if (!users) {
        this.selectedUsers = [];
        return;
      }

      // Si es multifirmas, limitar a 10 usuarios
      if (this.formDocument.is_multifirmas === 'true' && users.length > 10) {
        this.alert = {
          show: true,
          text: 'No se pueden agregar más de 10 personas para documentos multifirmas',
          type: 'error'
        };
        // Mantener solo los primeros 10 usuarios
        users = users.slice(0, 10);
      }
      
      // Procesar los usuarios para modificar el cargo y agregar la foto
      const processedUsers = users.map(user => ({
        ...user,
        photo: user.img || 'https://cdn.vuetifyjs.com/images/john.jpg',
        profile: {
          ...user.profile,
          name: user.profile?.name ? user.profile.name.split('|')[0].trim() : 'Sin cargo'
        }
      }));
      
      this.selectedUsers = processedUsers;
      this.listSelectedUsers = processedUsers;
      
      // Limpiar el texto de búsqueda
      this.search = '';
    },
    removeUser(user) {
      this.selectedUsers = this.selectedUsers.filter(u => u.id !== user.id);
      this.listSelectedUsers = this.selectedUsers;
    },
    handleSwitchChange(switchModel) {
      if (switchModel === 'is_external') {
        // Solo permitir activar documento externo si multifirmas está activo
        if (this.formDocument.is_multifirmas !== 'true') {
          this.formDocument.is_external = false;
          return;
        }

        if (this.formDocument.is_external === 'true') {
          // Si se activa documento externo
          this.formDocument.coordinator_signature = null;
          this.formDocument.html = '';
          this.pdfFile = null;
          this.fileError = '';
          
          // Ocultar el switch de firma del gerente
          const switchConfig = this.config.switches.find(s => s.model === 'coordinator_signature');
          if (switchConfig) {
            switchConfig.hidden = true;
          }
        } else {
          // Si se desactiva documento externo
          this.formDocument.html = '';
          this.pdfFile = null;
          this.fileError = '';
          
          // Mostrar el switch de firma del gerente solo si no es multifirmas
          const switchConfig = this.config.switches.find(s => s.model === 'coordinator_signature');
          if (switchConfig) {
            switchConfig.hidden = this.formDocument.is_multifirmas === 'true';
          }
        }
      } else if (switchModel === 'is_multifirmas') {
        // Si se activa multifirmas, ocultar el switch de firma del gerente
        const switchConfig = this.config.switches.find(s => s.model === 'coordinator_signature');
        if (switchConfig) {
          switchConfig.hidden = this.formDocument.is_multifirmas === 'true';
        }

        // Si se desactiva multifirmas, desactivar también documento externo
        if (this.formDocument.is_multifirmas !== 'true') {
          this.formDocument.is_external = false;
          this.formDocument.html = '';
          this.pdfFile = null;
          this.fileError = '';
        }
      }
    },
    updateUserCargo(user) {
      // Asegurarse de que el objeto profile existe
      if (!user.profile) {
        user.profile = {};
      }

      // Asegurarse de que el cargo no sea null
      if (!user.profile.name) {
        user.profile.name = 'Sin cargo';
      }

      // Actualizar el cargo en selectedUsers
      const index = this.selectedUsers.findIndex(u => u.id === user.id);
      if (index !== -1) {
        this.selectedUsers[index] = { ...user };
      }
      
      // Actualizar el cargo en listSelectedUsers
      const listIndex = this.listSelectedUsers.findIndex(u => u.id === user.id);
      if (listIndex !== -1) {
        this.listSelectedUsers[listIndex] = { ...user };
      }

      // Forzar la actualización de la vista
      this.$forceUpdate();
    },
    async importData() {
      try {
        this.loadingBtn = true;
        const dataForm = new FormData();
        var info = "sin_valor";
        if (this.formDocument.check_value) {
          info = "valor";
        }
        dataForm.append("check_value", info);
        dataForm.append("file", this.file);
        
        const res = await Api.Auth().importUserBonus(dataForm, this.token);
        if (res.data.cod === 0) {
          this.openImport = false;
          this.alert = {
            show: true,
            type: 'success',
            text: res.data.message
          };

          // Procesar usuarios importados
          const importedUsers = res.data.data.map(user => ({
            id: user.id,
            name: this.toTitleCase(user.full_name),
            document_number: user.document_number,
            photo: user.img || 'https://cdn.vuetifyjs.com/images/john.jpg',
            profile: {
              name: user.profile?.name ? user.profile.name.split('|')[0].trim() : 'Sin cargo'
            },
            full_name: user.full_name
          }));

          // Actualizar listas de usuarios
          this.selectedUsers = [...this.selectedUsers, ...importedUsers];
          this.listSelectedUsers = [...this.listSelectedUsers, ...importedUsers];
          this.file = null;
        }
      } catch (error) {
        console.error('Error al importar:', error);
        this.alert = {
          show: true,
          type: 'error',
          text: error.response?.data?.message || 'Error al importar el archivo'
        };
      } finally {
        this.loadingBtn = false;
      }
    },
    toTitleCase(str) {
      return str.toLowerCase().split(' ').map(word => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      }).join(' ');
    },
    async saveTemplate() {
      try {
        this.loadingBtn = true;
        
        if (!this.formTemplate.name || !this.formTemplate.description) {
          this.alert = {
            show: true,
            text: 'Por favor complete todos los campos requeridos',
            type: 'error'
          };
          return;
        }

        const templateData = {
          name: this.formTemplate.name,
          description: this.formTemplate.description,
          html: this.formDocument.html,
          type: this.config.documentType,
          area: this.config.area
        };

        const response = await Api.DocumentTemplate().saveDocumentTemplate(this.token, templateData);
        
        if (response && response.data) {
          this.alert = {
            show: true,
            text: 'Plantilla guardada correctamente',
            type: 'success'
          };
          this.openTemplate = false;
          await this.getTemplate();
          
          // Limpiar el formulario
          this.formTemplate = {
            html: "",
            type: this.config.documentType || '',
            name: "",
            description: "",
          };
        }
      } catch (error) {
        console.error('Error al guardar la plantilla:', error);
        this.alert = {
          show: true,
          text: error.response?.data?.message || 'Error al guardar la plantilla',
          type: 'error'
        };
      } finally {
        this.loadingBtn = false;
      }
    },
  },
  created() {
    this.getToken();
    
    // Inicializar el estado de multifirmas desde la configuración
    const multifirmasSwitch = this.config.switches.find(s => s.model === 'is_multifirmas');
    if (multifirmasSwitch && multifirmasSwitch.default) {
      this.formDocument.is_multifirmas = 'true';
    }

    // Inicializar el tipo de documento
    this.formDocument.document_type = this.config.documentType;
    this.formDocument.document = this.formDocument.is_multifirmas === 'true' ? 'multiples_firmas' : this.config.document;
  },
  mounted() {
    // Eliminado el código del alert inicial
  },
};
</script>

<style>
@import "./../../../assets/css/main.less";
/* ... estilos existentes ... */
</style>

<style scoped>
.document-managmet___title {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 59px;
  color: #353535;
}

.document-managmet___subtitle {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: flex-end;
  color: #353535;
}

.document-managmet___txt-dark {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #353535;
}

.document-managmet___txt {
  font-family: "RobotoRegular";
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  color: #626262;
}

.document-manager__button:hover {
  background-color: #324ca1 !important;
}

.variable-carousel {
  padding: 12px;
  border-radius: 8px;
  background-color: #f5f5f5;
  min-height: 70px;
  display: flex;
  align-items: center;
}

.v-chip {
  cursor: pointer;
  transition: all 0.3s ease;
}

.v-chip:hover {
  transform: translateY(-2px);
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.capitalize-text {
  text-transform: capitalize !important;
}

.v-list-item__title.capitalize-text {
  text-transform: capitalize !important;
}

.v-chip.v-size--small {
  text-transform: capitalize !important;
}

::v-deep .v-select__selections .v-chip {
  text-transform: capitalize !important;
}

/* Estilos responsivos */
@media (max-width: 1023px) {
  .document-managmet___title {
    font-size: 40px;
    line-height: 47px;
  }
}

@media (max-width: 767px) {
  .document-managmet___title {
    font-size: 35px;
    line-height: 41px;
  }
}

@media (max-width: 375px) {
  .document-managmet___title {
    font-size: 30px;
    line-height: 35px;
  }
}
</style> 